
























import {Component, Vue} from 'vue-property-decorator';

import {QrcodeStream, QrcodeCapture} from 'vue-qrcode-reader';
import {UserModule} from '@/store/modules/user';

@Component({
    components: {
        QrcodeStream,
        QrcodeCapture
    }
})
export default class QRCode extends Vue {
    private error = '';

    private decodedStr = '';

    private camera = 'auto';

    async onDecode(decodedString: string): Promise<void> {
        this.decodedStr = decodedString;

        this.turnCameraOff();

        if (this.decodedStr.toLowerCase().includes('/account/login/')) {
            const el = document.createElement('a');
            el.href = this.decodedStr;
            this.$store.commit('setLoading', false);

            if (!UserModule.authenticated) {
                this.$router.replace({path: el.pathname});
            } else {
                this.$store.commit('setLoading', false);
                this.$store.commit('showSnackbar', {
                    displayed: true,
                    color: 'error',
                    text: this.$i18n.t('qrcode.message.alreadyAuthenticated').toString(),
                    timeout: 10000
                });
                this.$router.replace({path: '/'});
            }

            return;
        }

        this.$store.commit('setLoading', false);

        // some more delay, so users have time to read the message
        await this.timeout(2000);

        this.turnCameraOn();
    }

    async onInit(promise: Promise<any>): Promise<void> {
        try {
            await promise;
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                this.error =
                    "Vous devez autoriser l'application à  utiliser l'appareil photo & à accéder aux fichiers.";
            } else if (error.name === 'NotFoundError') {
                this.error =
                    "Le scan de QR-Code n'est pas disponible sur cet appareil, vous pouvez sélectionner une image à scanner.";
            } else if (error.name === 'NotSupportedError') {
                this.error = 'ERROR: secure context required (HTTPS, localhost)';
            } else if (error.name === 'NotReadableError') {
                this.error =
                    "L'application n'arrive pas à accéder au flux vidéo, l'appareil photo est peut-être déjà en cours d'utilisation ?";
            } else if (error.name === 'OverconstrainedError') {
                this.error = 'ERROR: installed cameras are not suitable';
            } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = 'ERROR: Stream API is not supported in this browser';
            }
        }
    }

    async onDetect(promise: Promise<any>): Promise<void> {
        this.$store.commit('setLoading', true);
        this.error = 'Envoi en cours...';
        this.turnCameraOff();
        try {
            const {
                // imageData, // raw image data of image/frame
                content // decoded String or null
                // location // QR code coordinates or null
            } = await promise;

            if (content !== null) {
                await this.onDecode(content);
            } else {
                this.$store.commit('showSnackbar', {
                    displayed: true,
                    color: 'warning',
                    text: this.$i18n.t('qrcode.message.noCodeDetected').toString(),
                    timeout: 10000
                });
            }
        } catch (error) {
            // error...
        }
        this.$store.commit('setLoading', false);
        this.error = '';
        this.turnCameraOn();
    }

    turnCameraOn(): void {
        this.camera = 'auto';
    }

    turnCameraOff(): void {
        this.camera = 'off';
    }

    timeout(ms: number): Promise<() => void> {
        return new Promise(resolve => {
            window.setTimeout(resolve, ms);
        });
    }

    private triggerCapture() {
        const inputVueEl = this.$refs.inputQrcodeCapture;
        ((inputVueEl as Vue).$el as HTMLInputElement).click();
    }
}
